<template>
  <div class="editVideoRecord">
    <div class="table-container">
      <el-table
        v-loading = "loading"
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        :default-sort="{ prop: 'liveStartTime', order: 'descending' }"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="userName"
          align="center"
          min-width="120"
          type="index"
          label="序号"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          min-width="150"
          align="center"
          label="会员名称"
        >
        </el-table-column>
        <el-table-column
          prop="shareTime"
          min-width="150"
          align="center"
          label="分享时间"
          sortable
        ></el-table-column>
        <el-table-column
          prop="visitedCount"
          align="center"
          min-width="120"
          label="浏览量"
        >
        </el-table-column>
        <el-table-column
          prop="liveVideoUrl"
          min-width="168"
          align="center"
          label="剪辑视频内容"
        >
          <template slot-scope="scope">
            <img
              class="liveClips"
              @click="liveClips(scope.row.clipVideoUrl)"
              src="@/assets/images/live_broadcast/clips.png"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="memo"
          label="备注"
          min-width="160"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
    <!-- <video-player ref="videoPlayer" :livePlay="livePlay" :options="videoOptions"></video-player> -->
  </div>
</template>
<script>
import apis from "@/apis";
import LiveClass from "./components/videoClass";
import { mapState } from "vuex";
import mixin from "@/mixins/index";

export default {
  mixins: [mixin],
  components: {},
  data() {
    return {
      livePlay: false,
      // videoOptions: {
      //     autoplay: true,
      //     controls: true,
      // },
      tableData: [],
      form: new LiveClass("form"),
      loading: false,
    };
  },
  computed: {
    ...mapState(["Language", "TOKEN", "venueId"]),
  },
  methods: {
    searchData() {
      console.log(this.$route)
      this.loading = true
      const formData = {
        ...this.form,
        liveVideoId: this.$route.query.id,
      };
      this.$http
        .post(`${apis.getClipsListByLiveId}`, formData)
        .then((res) => {
          // console.log('clips', res);
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
  //创建完成
  created() {},
  //挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
.editVideoRecord {
  padding: 20px;
}
</style>
