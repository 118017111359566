/*
 * @Author: your name
 * @Date: 2020-11-26 11:03:36
 * @LastEditTime: 2020-11-28 14:47:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\liveBroadcast\shareClass.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
constructor(type) {
    super()
    if (type === 'form') {
        this.deviceId = ''
        this.userName = ''
    }
}
}
export default searchFrom
